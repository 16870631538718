import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Card, CardBody, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import Country from './Country'
import ExpertiseArea from './ExpertiseArea'

const numberRegex = RegExp(
    /^[0-9]*$/
);
const passwordRegex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
);

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};
class Register extends Component {

    constructor(props) {

        super(props)

        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            password: null,
            passwordValue: null,
            confirmPassword: null,
            yearsOfExperience: null,
            companyName: null,
            currentTitle: null,
            country: 'Afghanistan',
            small: false,
            errorMessage: '',
            hideError: true,
            loading: false,
            formErrors: {
                password: "",
                confirmPassword: "",
                companyName: "",
                currentTitle: "",
                yearsOfExperience: "",
            },

            options: [],
        }
        this.toggleSmall = this.toggleSmall.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search)
        const email = params.email
        const firstName = params.firstName
        const lastName = params.lastName

        this.setState({
            email: email,
            firstName: firstName,
            lastName: lastName
        })
    }

    updateValue(value) {
        this.setState({ passwordValue: value })
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            loading: true,
            hideError: true
        })

        if (formValid(this.state)) {

            fetch("https://f0681ycceg.execute-api.us-west-2.amazonaws.com/dev8/updateExpert", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.state.email,
                    companyName: this.state.companyName,
                    yearsOfExperience: this.state.yearsOfExperience,
                    locale: this.state.country,
                    currentTitle: this.state.currentTitle,
                    areasOfExpertise: this.state.options.toString(),
                    password: this.state.password,
                    firstName: this.state.firstName,
                })
            }).then((res) => {
                return res.json();
            }).then((data) => {
                console.log(data.body.result.passwordChange.password_change_success);

                if (data.body.result.passwordChange.password_change_success === 'False') {
                    this.setState({
                        errorMessage: 'The account attached to this email has already been activated. Please contact your system Admin for more help.',
                        hideError: false,
                        loading: false
                    });
                }
                if (data.body.result.passwordChange.password_change_success === 'True') {
                    this.toggleSmall()
                }


            }).catch(err => {
                console.log(err)
            })

        } else {
            alert('form is invalid')
        }
    };

    handleChange = e => {
        e.preventDefault();

        this.setState({
            hideError: true
        })

        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };

        switch (name) {
            case "companyName":
                formErrors.companyName =
                    value.length < 1 ? "minimum 1 characaters required" : "";
                break;
            case "currentTitle":
                formErrors.currentTitle =
                    value.length < 1 ? "minimum 1 characaters required" : "";
                break;
            case "yearsOfExperience":
                formErrors.yearsOfExperience = numberRegex.test(value)
                    ? ""
                    : "invalid Number";
                break;
            case "password":
                this.updateValue(value)
                formErrors.password = passwordRegex.test(value)
                    ? ""
                    : "Password should be at least 8 characters long. Password should contain at least 1 Uppercase, 1 Lowercase, 1 Specialcase and 1 Number.";
                break;
            case "confirmPassword":
                formErrors.confirmPassword =
                    value !== this.state.passwordValue ? "Passwords do not match" : "";
                break;
            default:
                break;
        }

        this.setState({ formErrors, [name]: value });
    };

    toggleSmall() {
        this.setState({
            small: !this.state.small,
        });
    }

    closeModal() {
        this.setState({
            small: !this.state.small
        });
        this.props.history.push('/dashboard')
    }

    updateCountry(selectedCountry) {
        this.setState({
            country: selectedCountry
        })
    }

    updateExpertiseArea(selectedAreas) {
        this.setState({
            options: selectedAreas
        })
    }

    render() {
        const { formErrors } = this.state;
        const disable = formValid(this.state)
        return (
            <div style={{ marginTop: 40 }} className="container flex-row align-items-center">
                <Row className="justify-content-center">
                    <Col md="8">
                        <Card className="p-1">
                            <CardBody>
                                <Form onSubmit={this.handleSubmit} noValidate>
                                    <div className="container">
                                        <div style={{ textAlign: 'center' }}>
                                            <h3 style={{ color: '#5DADE2', textAlign: 'center' }}>You've been invited as a Posture Expert</h3>
                                            <p className="text-muted">Let's get started on setting up your account</p>
                                        </div>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-at"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input readOnly={true} value={this.state.email} type="text" autoComplete="username" />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input readOnly={true} value={this.state.firstName} type="text" autoComplete="username" />
                                            <InputGroupAddon style={{ marginLeft: 10 }} addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input readOnly={true} value={this.state.lastName} type="text" autoComplete="username" />
                                        </InputGroup>

                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-pencil"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.companyName.length > 0 ? "is-invalid" : null}
                                                placeholder="Company Name"
                                                type="text"
                                                name="companyName"
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.companyName.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.companyName}</span>
                                        )}
                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-pencil"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.currentTitle.length > 0 ? "is-invalid" : null}
                                                placeholder="Current Title"
                                                type="text"
                                                name="currentTitle"
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.currentTitle.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.currentTitle}</span>
                                        )}
                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-globe"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Country selectCountry={this.updateCountry.bind(this)} />
                                        </InputGroup>
                                        {formErrors.currentTitle.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.currentTitle}</span>
                                        )}
                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-calendar"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.yearsOfExperience.length > 0 ? "is-invalid" : null}
                                                placeholder="Years Of Experience"
                                                type="number"
                                                name="yearsOfExperience"
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.yearsOfExperience.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.yearsOfExperience}</span>
                                        )}
                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.password.length > 0 ? "is-invalid" : null}
                                                placeholder="Password"
                                                type="password"
                                                name="password"
                                                value={this.state.passwordValue}
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        <div style={{ color: 'red', marginLeft: 50, textAlign: 'center' }}>
                                        {formErrors.password.length > 0 && (
                                            <span  >{formErrors.password}</span>
                                        )}
                                        </div>
                                       
                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.confirmPassword.length > 0 ? "is-invalid" : null}
                                                placeholder="Confirm Password"
                                                type="password"
                                                name="confirmPassword"
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.confirmPassword.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.confirmPassword}</span>
                                        )}
                                        <p style={{ color: '#5DADE2' }}>Select your areas of expertise</p>
                                        <ExpertiseArea selectArea={this.updateExpertiseArea.bind(this)} />
                                        <div hidden={this.state.hideError} style={{ marginTop: 30 }} className="alert alert-danger" role="alert">
                                            <strong>ERROR! </strong>   {this.state.errorMessage}
                                            <button type="button" onClick={() => {
                                                this.setState({
                                                    hideError: true
                                                })
                                            }} className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {this.state.loading ?
                                            <Button
                                                disabled={this.state.loading}
                                                type='submit'
                                                style={{ marginTop: 10 }}
                                                color="primary"
                                                block
                                            >
                                                Loading. Please Wait
                                             </Button> :
                                            <Button
                                                disabled={!disable}
                                                type='submit'
                                                style={{ marginTop: 10 }}
                                                color="primary"
                                                block
                                            >Create Account</Button>}
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={this.state.small} toggle={this.toggleSmall}
                    className={'modal-sm ' + this.props.className}>
                    <ModalBody>
                        Your account has been created successfully. Click continue to be taken to your login page.
                        </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeModal}>Continue</Button>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }
}

export default withRouter(Register);
