import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { signin } from '../../../redux/actions/userActions'

export default function requireAuth(Component, param = null) {
  class UnAuthenticatedComponent extends React.Component {
    componentWillMount() {
      this.checkAuth()
    }

    componentDidUpdate(prevProps, prevState) {

      if (prevProps.user !== this.props.user && (this.props.user && this.props.user !== 'undefined')) {
        if (this.props.location.state && this.props.location.state.from) {
          this.props.history.push(this.props.location.state.from.pathname)
        } else {
          this.props.history.push('/dashboard')
        }
      } else {
        this.props.history.push('/')
      }
    }

    checkAuth() {
      if (!this.props.user || this.props.user === 'undefined') {
        if (localStorage.getItem('session')) {
          this.props.signin(JSON.parse(localStorage.getItem('session')))
        } else {
          // console.log('no item')
        }
      } else {
        if (this.props.location.state && this.props.location.state !== 'undefined') {
          this.props.history.push(this.props.location.state.from.pathname)
        } else {
          this.props.history.push('/')
        }
      }

    }

    render() {
      return !this.props.user
        ? <Component />
        : null
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.auth.user
    }
  }

  return withRouter(connect(mapStateToProps, { signin })(UnAuthenticatedComponent))
}
