import {
    SIGN_IN,
    SIGN_OUT
} from '../actions/userActions'

export default function (state = {}, action) {
    switch (action.type) {
        case SIGN_IN:
            return { ...state, user: action.payload }
        case SIGN_OUT:
            return { ...state, user: null }
        default:
            return state
    }
}