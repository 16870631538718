import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};

class Invite extends Component {

    constructor(props) {
        super(props)
        this.state = {
            firstName: null,
            lastName: null,
            email: null,
            errorMessage: '',
            successMessage: '',
            loading: false,
            hideError: true,
            hideSuccess: true,
            formErrors: {
                firstName: "",
                lastName: "",
                email: "",
            }
        }
    }

    handleSubmit = e => {

        e.preventDefault();
        this.setState({
            hideError: true,
            hideSuccess: true,
            loading: true
        })

        if (formValid(this.state)) {

            fetch("https://f1qfpn5rif.execute-api.us-west-2.amazonaws.com/dev7/saveExpert", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email
                })
            }).then((res) => {
                return res.json();
            }).then((data) => {

                if (data.body.result.response === 'User account already exists') {
                    this.setState({
                        hideError: false,
                        errorMessage: data.body.result.response,
                        loading: false
                    });
                }
                if (data.body.result.response === 'User added Successfully!') {
                    this.setState({
                        hideSuccess: false,
                        successMessage: data.body.result.response,
                        loading: false
                    });
                }

            }).catch(err => {
                console.log(err)
            })

        } else {
            alert('form is invalid')
        }
    };

    handleChange = e => {
        e.preventDefault();

        this.setState({
            hideError: true,
            hideSuccess: true
        })

        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };

        switch (name) {
            case "firstName":
                formErrors.firstName =
                    value.length < 3 ? "minimum 3 characaters required" : "";
                break;
            case "lastName":
                formErrors.lastName =
                    value.length < 3 ? "minimum 3 characaters required" : "";
                break;
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;
            default:
                break;
        }

        this.setState({ formErrors, [name]: value });
    };



    render() {

        const { formErrors } = this.state;
        const disable = formValid(this.state)
        return (
            <div style={{ marginTop: 80 }} className="container flex-row align-items-center">
                <Row className="justify-content-center">
                    <Col md="8">
                        <Card className="p-1">
                            <CardBody className="p-4">
                                <Form onSubmit={this.handleSubmit} noValidate>
                                    <h1 style={{ color: '#5DADE2' }}>Invite</h1>
                                    <p className="text-muted">Invite a expert you would want to onboard</p>
                                    <div hidden={this.state.hideError} style={{ marginTop: 30 }} className="alert alert-danger" role="alert">
                                        <strong>ERROR! </strong>   {this.state.errorMessage}
                                        <button type="button" onClick={() => {
                                            this.setState({
                                                hideError: true
                                            })
                                        }} className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div hidden={this.state.hideSuccess} style={{ marginTop: 30 }} className="alert alert-success" role="alert">
                                        <strong>SUCCESS! </strong>   {this.state.successMessage}
                                        <button type="button" onClick={() => {
                                            this.setState({
                                                hideSuccess: true
                                            })
                                        }} className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-user"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            className={formErrors.email.length > 0 ? "is-invalid" : null}
                                            placeholder="Email"
                                            type="text"
                                            name="email"
                                            noValidate
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    {formErrors.email.length > 0 && (
                                        <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.email}</span>
                                    )}

                                    <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-user"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            className={formErrors.firstName.length > 0 ? "is-invalid" : null}
                                            placeholder="First Name"
                                            type="text"
                                            name="firstName"
                                            noValidate
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    {formErrors.firstName.length > 0 && (
                                        <span style={{ color: 'red', marginLeft: 50 }}>{formErrors.firstName}</span>
                                    )}

                                    <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-user"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            className={formErrors.lastName.length > 0 ? "is-invalid" : null}
                                            placeholder="Last Name"
                                            type="text"
                                            name="lastName"
                                            noValidate
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    {formErrors.lastName.length > 0 && (
                                        <span style={{ color: 'red', marginLeft: 50 }}>{formErrors.lastName}</span>
                                    )}

                                    {this.state.loading ?
                                        <Button
                                            disabled={this.state.loading}
                                            type='submit'
                                            style={{ marginTop: 10 }}
                                            color="primary"
                                            block
                                        >
                                            Loading. Please Wait
                                             </Button> :
                                        <Button
                                            disabled={!disable}
                                            type='submit'
                                            style={{ marginTop: 10 }}
                                            color="primary"
                                            block
                                        >Invite</Button>}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default Invite;
