import React, { Component } from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, Jumbotron, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import NavbarItem from './../Layout/NavBar'
import checkedDefaultImg from '../../assets/img/check.png'
import checkedCompletedImg from '../../assets/img/check-green.png'

export default class Dashboard extends Component {

    state = {
        currentUserName: '',
        currentUserEmail: '',
        large: false,
        url: '',
        scenarios: [],
        completedScenarios: [],
        search: '',
    };

    updateSearch(event) {
        this.setState({ search: event.target.value })
    }

    getScenarios() {
        const email = localStorage.getItem('CognitoIdentityServiceProvider.3jj0sgn2oqspp587bs0qhba88p.LastAuthUser') || null;

        let getScenarios = fetch("https://1uussywzna.execute-api.us-east-1.amazonaws.com/dev/getExpertAssessments", {
            headers: {
                'Content-Type': "application/json",
                'x-api-key': 'Xhdznn8JWGa3E0lJ5FOlfaAB6k7WizIl2QALX3b3'
            },
            method: "GET"
        });
        let userResponses = fetch(`https://1uussywzna.execute-api.us-east-1.amazonaws.com/dev/getAssessmentByExpert?expertID=${email}`, {
            headers: {
                'Content-Type': "application/json",
                'x-api-key': 'Xhdznn8JWGa3E0lJ5FOlfaAB6k7WizIl2QALX3b3'
            },
            method: "GET"
        });

        Promise.all([getScenarios, userResponses])
            .then(values => Promise.all(values.map(value => value.json())))
            .then(finalVals => {
                let scenarios = finalVals[0];
                let completedScenarios = finalVals[1].map(completedScenario => completedScenario.scenarioID);

                this.setState({
                    scenarios,
                    completedScenarios
                });
            }).catch(e => {

            });
    }

    componentWillMount() {
        this.getScenarios()
    }


    setTypeFormUrl = url => {
        this.setState({
            large: !this.state.large,
            url: url
        });
    }

    closeModal = () => {
        this.getScenarios()
        this.setState({
            large: !this.state.large
        });
    }


    render() {
        let filterData;
        let email = localStorage.getItem('CognitoIdentityServiceProvider.3jj0sgn2oqspp587bs0qhba88p.LastAuthUser')
        if (this.state.search !== '') {
            filterData = this.state.scenarios.filter((item) => {
                return item["CompanyName"].toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
            })
        } else {
            filterData = this.state.scenarios;
        }

        return (

            <div>
                <NavbarItem></NavbarItem>
                <div className="container">
                    <Jumbotron style={{ backgroundColor: '#1F618D' }}>
                        <p className="lead" style={{ color: 'white' }}>How It Works: </p>
                        <hr className="my-2" style={{ backgroundColor: 'white' }} />
                        <p style={{ color: 'white', fontSize: 17, marginTop: '30' }}>Below you will see a list of scenarios. Open the ones related to your expertise. We will provide you with details about a specific fictitious company and then ask you a few questions on your recommendations based on the information provided. If the options are a "bad idea" please fill an alternative option in the comments section. Ready to get started?</p>
                    </Jumbotron>
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs="12" sm="6" md="4">
                            <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="icon-search"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    value={this.state.search}
                                    onChange={this.updateSearch.bind(this)}
                                    type="text"
                                    placeholder="Search" />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        {filterData.map((item, i) =>
                            (
                                <Col xs="12" sm="6" md="4" key={i}>
                                    <Card className="border-primary">
                                        <CardHeader >
                                            <strong >{item.CompanyName}</strong>
                                        </CardHeader>
                                        <CardBody>
                                            <div style={{ marginBottom: 10 }}>  <strong>  Expertise Area: </strong>{item.ExpertiseArea}</div>
                                            <Button style={{ marginTop: 50, backgroundColor: '#1F618D', color: 'white' }} onClick={() => { this.setTypeFormUrl(`${item.url}?email=${email}&id=${item.scenarioID}`) }} className="mr-1">
                                                {this.state.completedScenarios.includes(item.scenarioID) ? 'Redo' : 'Start'}
                                            </Button>
                                            {this.state.completedScenarios.includes(item.scenarioID) ?
                                                <img src={checkedCompletedImg} alt='checked'
                                                    style={{
                                                        width: '10%',
                                                        float: 'right',
                                                        marginTop: '55px',
                                                    }} />
                                                :
                                                <img src={checkedDefaultImg} alt='checked'
                                                    style={{
                                                        width: '10%',
                                                        float: 'right',
                                                        marginTop: '55px',
                                                        opacity: 0.3,
                                                    }} />
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        )}
                        <Modal isOpen={this.state.large}
                            className={'modal-lg ' + this.props.className} >
                            <ModalBody >
                                <ReactTypeformEmbed style={{ position: "relative", height: "83vh" }} url={this.state.url} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.closeModal}>Close</Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                </div >
            </div>
        )
    }
}
