import React, { Component } from 'react'
import { AppSwitch } from '@coreui/react'
export default class ExpertiseArea extends Component {

  constructor(props) {
    super(props)
    this.state = {
      options: []
    }
  }
  onChange(e) {
    const options = this.state.options

    if (options.includes(e.target.value)) {
      var index = options.indexOf(e.target.value);
      if (index !== -1) options.splice(index, 1);
    } else {
      options.push(e.target.value)
    }
    options.sort()
    this.setState({ options: options })
    this.props.selectArea(options)
  }

  render() {
    return (
      <div>
        <div className="container" style={{ marginBottom: 20 }}>
          <div className="row">
            <div className="col-6">
              <label >Data Privacy</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='Data Privacy' onChange={this.onChange.bind(this)} />
            </div>
            <div className="col-6">
              <label >Compliance Programs</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='Compliance Programs' onChange={this.onChange.bind(this)} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label >Cyber Security</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='Cyber Security ' onChange={this.onChange.bind(this)} />
            </div>
            <div className="col-6">
              <label >Governance</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='Governance' onChange={this.onChange.bind(this)} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label >Awareness Training</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='Awareness Training' onChange={this.onChange.bind(this)} />
            </div>
            <div className="col-6">
              <label >GDPR</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='GDPR' onChange={this.onChange.bind(this)} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label>PCI</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='PCI' onChange={this.onChange.bind(this)} />
            </div>
            <div className="col-6">
              <label >HIPAA</label>
            </div>
            <div className="col-6">
              <AppSwitch className={'mx-1'} color={'primary'} outline={'alt'} label value='HIPAA' onChange={this.onChange.bind(this)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
