import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Auth from '@aws-amplify/auth'
import { connect } from 'react-redux'
import { signin } from '../../redux/actions/userActions'
import { withRouter } from 'react-router-dom'


const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);


const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};



class Login extends Component {

  constructor(props) {
    super(props)


    this.state = {
      email: '',
      password: null,
      errorMessage: '',
      hideError: true,
      loading: false,
      formErrors: {
        email: "",
        password: "",
      },

    }
  }


  handleSubmit = async e => {
    e.preventDefault();

    this.setState({
      loading: true
    })

    if (formValid(this.state)) {
      try {
        const user = await Auth.signIn(this.state.email, this.state.password)
        localStorage.setItem('session', JSON.stringify(user))
        this.props.signin(user)
        this.props.history.push('/dashboard')
      } catch (e) {
        this.setState({
          hideError: false,
          errorMessage: e.message,
          loading: false
        })
      }
    } else {
      alert('form is invalid')
    }
  };


  handleChange = e => {
    e.preventDefault();

    this.setState({
      hideError: true
    })
    const { name, value } = e.target;

    let formErrors = { ...this.state.formErrors };

    switch (name) {

      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;

      case "password":
        formErrors.password = value.length < 1 ? "Password is required."
          : "";
        break;
      default:
        break;
    }

    this.setState({
      formErrors, [name]: value
    });
  };
  render() {
    const { formErrors } = this.state;
    const disable = formValid(this.state)
    return (
      <div style={{ marginTop: 40 }} className="container flex-row align-items-center">
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form onSubmit={this.handleSubmit} noValidate>
                    <div className="container">
                      <div style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#5DADE2' }}>Welcome to Posture Experts</h3>
                        <img src={'postureLogin.png'} alt='' width="100%" height="100%"></img>
                      </div>
                    </div>
                    <div hidden={this.state.hideError} style={{ marginTop: 30 }} className="alert alert-danger" role="alert">
                      <strong>ERROR! </strong>   {this.state.errorMessage}
                      <button type="button" onClick={() => {
                        this.setState({
                          hideError: true
                        })
                      }} className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <p style={{ color: '#5DADE2', marginTop: 10 }}>Sign In to your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-at"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className={formErrors.email.length > 0 ? "is-invalid" : null}
                        placeholder="Email"
                        type="text"
                        name="email"
                        noValidate
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    {formErrors.email.length > 0 && (
                      <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.email}</span>
                    )}
                    <InputGroup style={{ marginTop: 20 }} className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className={formErrors.password.length > 0 ? "is-invalid" : null}
                        placeholder="Password"
                        type="password"
                        name="password"
                        noValidate
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    {formErrors.password.length > 0 && (
                      <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.password}</span>
                    )}
                    <div style={{ marginLeft: 100, marginRight: 100 }}>
                      {this.state.loading ?
                        <Button
                          disabled={this.state.loading}
                          type='submit'
                          style={{ marginTop: 10 }}
                          color="primary"
                          block
                        >
                          Loading. Please Wait
                                             </Button> :
                        <Button
                          disabled={!disable}
                          type='submit'
                          style={{ marginTop: 10 }}
                          color="primary"
                          block
                        >Login</Button>}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </div >
    );
  }
}

export default withRouter(connect(null, { signin })(Login));
