import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'



const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};
class Register extends Component {

    constructor(props) {
        super(props)


        this.state = {
            disableButton: true,
            email: '',
            firstName: '',
            lastName: '',
            password: null,
            passwordValue: null,
            confirmPassword: null,
            yearsOfExperience: null,
            companyName: null,
            currentTitle: null,
            country: 'select',
            small: false,
            message: '',
            formErrors: {
                password: "",
                confirmPassword: "",
                companyName: "",
                currentTitle: "",
                yearsOfExperience: "",
            },

            options: [],
        }
        this.toggleSmall = this.toggleSmall.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }



    componentDidMount() {

        if (formValid(this.state)) {
            this.setState({
                disableButton: false
            })
        }
        const params = queryString.parse(this.props.location.search)
        const email = params.email
        const firstName = params.firstName
        const lastName = params.lastName

        this.setState({
            email: email,
            firstName: firstName,
            lastName: lastName
        })
    }


    updateValue(value) {
        this.setState({ passwordValue: value })
    }
    handleSubmit = e => {
        e.preventDefault();

        if (formValid(this.state)) {

            fetch("https://f0681ycceg.execute-api.us-west-2.amazonaws.com/dev8/updateExpert", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.state.email,
                    companyName: this.state.companyName,
                    yearsOfExperience: this.state.yearsOfExperience,
                    locale: this.state.country,
                    currentTitle: this.state.currentTitle,
                    areasOfExpertise: this.state.options,
                    password: this.state.password,
                    firstName: this.state.firstName,
                })
            }).then((res) => {
                return res.json();
            }).then((data) => {

                this.setState({
                    message: data.body.result.response
                });
                this.toggleSmall()
            }).catch(err => {
                console.log(err)
            })

        } else {
            alert('form is invalid')
        }
    };

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };
        switch (name) {
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;

            default:
                break;
        }

        this.setState({
            formErrors, [name]: value
        });
    };

    onChange(e) {

        const options = this.state.options

        if (options.includes(e.target.value)) {
            var index = options.indexOf(e.target.value);
            if (index !== -1) options.splice(index, 1);
        } else {
            options.push(e.target.value)
        }
        options.sort()
        this.setState({ options: options })
    }


    toggleSmall() {
        this.setState({
            small: !this.state.small,
        });
    }

    closeModal() {
        this.setState({
            small: !this.state.small
        });
        this.props.history.push('/dashboard')
    }

    handleCountryChange(e) {
        this.setState({
            country: e.target.value
        })
    }

    render() {
        const { formErrors } = this.state;
        const disable = formValid(this.state)
        return (
            <div style={{ marginTop: 100 }} className="app flex-row align-items-center">
                <Container>


                    <Row className="justify-content-center">

                        <Col md="9" lg="7" xl="6">

                            <Card className="mx-4">
                                <CardBody className="p-4">
                                    <Form onSubmit={this.handleSubmit} noValidate>
                                        <div style={{ textAlign: 'center' }}>
                                            <h3 style={{ color: '#5DADE2', marginBottom: 30, textAlign: 'center' }}>Change Password</h3>
                                            <p className="text-muted">Let's get started on setting changing your password</p>
                                        </div>
                                        <div className="alert alert-primary" role="alert">Password Recovery successful. Check your email for verification code.</div>

                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-pencil"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.companyName.length > 0 ? "is-invalid" : null}
                                                placeholder="Verification Code"
                                                type="number"
                                                name="companyName"
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.companyName.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.companyName}</span>
                                        )}

                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-at"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input readOnly={true} value={this.state.email} type="text" autoComplete="username" />
                                        </InputGroup>


                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.password.length > 0 ? "is-invalid" : null}
                                                placeholder="Password"
                                                type="password"
                                                name="password"
                                                value={this.state.passwordValue}
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.password.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.password}</span>
                                        )}


                                        <InputGroup style={{ marginTop: 20 }} className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className={formErrors.confirmPassword.length > 0 ? "is-invalid" : null}
                                                placeholder="Confirm Password"
                                                type="password"
                                                name="confirmPassword"
                                                noValidate
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        {formErrors.confirmPassword.length > 0 && (
                                            <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.confirmPassword}</span>
                                        )}

                                        <Button disabled={!disable} type="submit" color="primary" block>Change Password</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.small} toggle={this.toggleSmall}
                        className={'modal-sm ' + this.props.className}>
                        <ModalBody>
                            Your account has been created successfully. Click continue to be taken to your login page.
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.closeModal}>Continue</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </div >
        );
    }
}

export default withRouter(Register);
