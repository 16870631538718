import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};

class ForgetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: null,
            errorMessage: '',
            showError: true,
            formErrors: {
                email: "",
            }
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        if (formValid(this.state)) {
            fetch("https://f1qfpn5rif.execute-api.us-west-2.amazonaws.com/dev7/saveExpert", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email
                })
            }).then((res) => {
                return res.json();
            }).then((data) => {

            }).catch(err => {
                console.log(err)
            })
        } else {
            alert('form is invalid')
        }
    };

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };
        switch (name) {
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;
            default:
                break;
        }
        this.setState({ formErrors, [name]: value });
    };

    render() {

        const { formErrors } = this.state;
        const disable = formValid(this.state)
        return (
            <div style={{ marginTop: 80 }} className="container flex-row align-items-center">
                <Row className="justify-content-center">
                    <Col md="6">
                        <Card className="p-1">
                            <CardBody className="p-4">
                                <Form onSubmit={this.handleSubmit} noValidate>
                                    <h3 style={{ color: '#5DADE2' }}>Forget Password</h3>
                                    <p className="text-muted">Enter your email to start your recovery process</p>
                                    <Row>
                                        <Col >
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-at"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    className={formErrors.email.length > 0 ? "is-invalid" : null}
                                                    placeholder="Email"
                                                    type="text"
                                                    name="email"
                                                    noValidate
                                                    onChange={this.handleChange}
                                                />
                                            </InputGroup>
                                            {formErrors.email.length > 0 && (
                                                <span style={{ color: 'red', marginLeft: 50 }} >{formErrors.email}</span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <Button disabled={!disable} style={{ marginTop: 10 }} type="submit" color="primary" block>Reset Password</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ForgetPassword;
