import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Register from './components/Auth/Register'
import Invite from './components/Auth/Invite'
import Login from './components/Auth/Login';
import ForgetPassword from './components/Auth/ForgetPassword'
import requireAuth from './components/Auth/hoc/requireAuth'
import requireUnAuth from './components/Auth/hoc/requireUnAuth'
import changePassword from './components/Auth/changePassword'
import Dashboard from './components/Pages/Dashboard'
class App extends Component {



  render() {
    return (
      <Router>
        <div className="App">
          <div >
            <Route path="/" exact={true} component={requireUnAuth(Login)} />
            <Route path="/register" exact={true} component={requireUnAuth(Register)} />
            <Route path="/invite" exact={true} component={requireUnAuth(Invite)} />
            <Route path="/changePassword" exact={true} component={requireUnAuth(changePassword)} />
            <Route path="/forgetPassword" exact={true} component={requireUnAuth(ForgetPassword)} />
            <Route path="/dashboard" exact={true} component={requireAuth(Dashboard)} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
