import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'

import {
    userReducer
} from '../src/redux/reducers/index'

const reducer = combineReducers({
    auth: userReducer
})

const store = createStore(reducer,
    compose(
        applyMiddleware(reduxThunk),
        window.window.__REDUX_DEVTOOLS_EXTENSION__ ? window.window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ))

export default store
