import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { signin } from '../../../redux/actions/userActions'

export default function requireAuth(Component, param = {}) {
  class AuthenticatedComponent extends React.Component {
    componentWillMount() {
      this.checkAuth()
    }

    checkAuth() {
      if (!this.props.user) {
        // console.log(this.props.location)
        const location = {
          pathname: '/',
          state: { from: { pathname: `${this.props.location.pathname}${this.props.location.search}` } }
        }

        localStorage.getItem('session')
          ? this.props.signin(JSON.parse(localStorage.getItem('session')))
          : this.props.history.push(location)
      }
    }

    render() {
      return this.props.user
        ? <Component
          {...this.props} />
        : 'ERROR NO ALLOWED'

    }
  }

  function mapStateToProps(state) {
    return {
      user: state.auth.user
    }
  }

  return withRouter(connect(mapStateToProps, { signin })(AuthenticatedComponent))
}
