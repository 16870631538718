import jwtDecode from 'jwt-decode'
export const SIGN_IN = 'signin'
export const SIGN_OUT = 'signout'

export function signin (user) {
  return {
    type: SIGN_IN,
    payload: (user && user.signInUserSession) ?
      jwtDecode(user.signInUserSession.idToken.jwtToken) :
      null
  }
}

export function signout () {
  localStorage.clear()
  sessionStorage.clear()
  return {
    type: SIGN_OUT
  }
}
