import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { signout } from '../../redux/actions/userActions'
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';


class NavbarItem extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <div style={{ paddingBottom: 50 }} >
                <Navbar style={{ backgroundColor: '#1F618D' }} light expand="md">
                    <NavbarBrand href="/" style={{ color: 'white' }}>Posture Expert Assessment</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Button type="submit" color="primary" onClick={() => {
                                    this.props.signout()
                                    this.props.history.push('/')
                                }} block>Logout</Button>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>


        );
    }
}

export default withRouter(connect(null, { signout })(NavbarItem));